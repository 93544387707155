import { ArticleHeading } from '../content'
import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import FAQ from '../FAQ'
import React from 'react'
import P from '@bootstrap-styled/v4/lib/P'
import A from '@bootstrap-styled/v4/lib/A'

const HomeFaq = function () {
  return <section>
    <ArticleHeading className={'text-center pb-5'}>Local Physio Network FAQ's</ArticleHeading>
    <Row className={'justify-content-center'}>
      <Col xs={12} md={9}>
        <FAQ items={[
          {
            title: 'What is the Local Physio Network?',
            content: <React.Fragment><P>Local Physio Network / LPN (localphysio.net.au) is a digital technology company that helps patients find the best physiotherapist in their local area. We have relationships with physiotherapy clinics Australia wide.</P></React.Fragment>,
          },
          {
            title: 'How much does Local Physio Network cost?',
            content: <React.Fragment><P>The Local Physio Network doesn’t cost you anything! Simply search for your desired location, find a clinic near you and make a booking.</P></React.Fragment>,
          },
          {
            title: 'Who is Local Physio Network?',
            content: <React.Fragment><P>Local Physio Network is a booking platform created by two Aussie entrepreneurs, looking to assist patients in finding and booking a local physiotherapist.</P></React.Fragment>,
          },
          {
            title: 'How does Local Physio Network work?',
            content: <React.Fragment><P>We have partnerships with physiotherapy clinics right across Australia. No matter where you are in this beautiful country, you should be able to find a physiotherapist near you.</P></React.Fragment>,
          },
          {
            title: 'Is Local Physio Network a Physiotherapy clinic?',
            content: <React.Fragment><P>The Local Physio Network is not actually a clinic. We have relationships with clinics all across Australia that provide physiotherapy services. We simply connect you the patient, with the clinic that is most convenient for you!</P></React.Fragment>,
          },
          {
            title: 'Why use Local Physio Network?',
            content: <React.Fragment><P>We make it easy for you to connect with your local physiotherapist. Whether you want to make a booking on a Sunday afternoon or even in the middle of the night! Our system is available to you 24/7. Choose a time and location that suits you, all from the palm of your hand.</P></React.Fragment>,
          },
          {
            title: 'Does Local Physio Network work in my area?',
            content: <React.Fragment><P>Yes, we have partner clinics right across Australia who are ready to help you. Whether it’s a sore back or a dodgy knee, we have a clinic who can help you!</P></React.Fragment>,
          },
          {
            title: 'Is Local Physio Network safe?',
            content: <React.Fragment><P>Absolutely, we understand the sensitive and private information needs to be secure. Our servers are locally based with high levels of security and protection.</P></React.Fragment>,
          },
          {
            title: 'Is Local Physio Network Australian?',
            content: <React.Fragment>
              <P>Yes, LPN started in Sydney and has a network of clinics right across Australia.</P>
              <P>The Local Physio Network is also the owner-operator of <A href={'https://bookphysio.com'}>Book Physio</A>.</P>
              </React.Fragment>,
          },
        ]}/>
      </Col>
    </Row>
  </section>
}

export default HomeFaq
