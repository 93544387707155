import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Container from '@bootstrap-styled/v4/lib/Container'
import P from '@bootstrap-styled/v4/lib/P'
import Img from '@bootstrap-styled/v4/lib/Img'
import Section from '@bootstrap-styled/v4/lib/Section'
import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import Head from 'next/head'
import { ArticleHeading } from '../components/content'
import HomeJumbotron from '../components/home/HomeJumbotron'
import { NavBar, Toggler } from '../components/layout/NavBar'
import { NavBarButton, PageBody } from '../components/pages/Location'
import Footer from '../components/layout/Footer'
import dynamic from 'next/dynamic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import HomeFooter from '../components/home/HomeFooter'
import BookingInput from '../components/booking/BookingInput'
import { useBooking } from '../components/booking/BookingFormProvider'
import useScrollPosition from '@react-hook/window-scroll'
import { H2 } from '../components/Typography'
import HomeFaq from '../components/home/HomeFaq'
import PhysioFaq from '../components/home/PhysioFaq'
import BottomBar from '../components/BottomBar'

import cities from "../data/cities.json";
import suburbs from "../data/suburbs.json";
import services from "../data/services.json";
import faq from "../data/faq.json";
import injuries from "../data/injuries.json";

const BookingModal = dynamic(() => import('../components/booking/BookingModal'))

const LogoImg = styled(Img)`
  display: block;
  margin: auto auto;
  max-height: 80px;
  max-width: ${props => props.maxWidth || '70%'};
`

const LogoBar = styled('div')`
  background-color: #f9f9fa;
  padding: 50px 0 50px;
  margin: 30px 0 150px;
  text-align: center;
  ${props => props.noMobile && `
    @media (max-width: 768px) {
      display: none;
    }
  `}
`

const LogoCol = styled(Col)`
  display: flex;
  justify-content: center;
  @media (max-width:768px) {
    display: ${props => props.hiddenXs ? 'none' : 'block'};
  }
`

const LogoBlock = styled('div')`
  padding: 30px;
  width: 20%;
  @media (max-width: 768px) {
    display: ${props => props.hiddenXs ? 'none' : 'block'};
    width: 50%;
  }
  display: flex;
  align-items: center;
`

const sponsors = [
  { title: 'BUPA', image: 'https://assets.localphysio.net.au/images/logos/bupa.png', xs: true },
  { title: 'Teachers Health Centre', image: 'https://assets.localphysio.net.au/images/logos/thc.png', xs: true },
  { title: 'HBF', image: 'https://assets.localphysio.net.au/images/logos/hbf.png', xs: true },
  { title: 'HCF', image: 'https://assets.localphysio.net.au/images/logos/hcf.png', xs: true },
  { title: 'TUH Health Fund', image: 'https://assets.localphysio.net.au/images/logos/tuh.png', xs: true },
  { title: 'Frank Health Insurance', image: 'https://assets.localphysio.net.au/images/logos/frank.png', xs: true },
  { title: 'Medibank', image: 'https://assets.localphysio.net.au/images/logos/medibank.png', xs: true },
  { title: 'Australian Unity', image: 'https://assets.localphysio.net.au/images/logos/australian-unity.png', xs: true },
  { title: 'NIB', image: 'https://assets.localphysio.net.au/images/logos/nib.png', xs: true },
  { title: 'Latrobe', image: 'https://assets.localphysio.net.au/images/logos/latrobe.png', xs: true },
  { title: 'AHM', image: 'https://assets.localphysio.net.au/images/logos/ahm.png', xs: true },
  { title: 'CUA Health', image: 'https://assets.localphysio.net.au/images/logos/cua.png', xs: true },
  { title: 'health.com.au', image: 'https://assets.localphysio.net.au/images/logos/health-com-au.png', xs: true },
  { title: 'HIF', image: 'https://assets.localphysio.net.au/images/logos/hif.png', xs: true },
  { title: 'Defence Health', image: 'https://assets.localphysio.net.au/images/logos/defence-health.png', xs: false },
]

const news = [
  { title: 'ABC Australia', image: 'https://assets.localphysio.net.au/images/logos/abc-australia-g.png', xs: false },
  { title: '9news', image: 'https://assets.localphysio.net.au/images/logos/9news-g.png', xs: true },
  { title: 'The Daily Telegraph', image: 'https://assets.localphysio.net.au/images/logos/daily-telegraph-g.png', xs: true },
  { title: 'Sunday Morning Gerald', image: 'https://assets.localphysio.net.au/images/logos/sunday-morning-herald-g.png', xs: false },
  { title: 'news.com.au', image: 'https://assets.localphysio.net.au/images/logos/news-com-au-g.png', xs: false },
]

const Steps = styled(Row)`
  color: ${props => props.theme['$headings-color']};
  // text-align: center;
  padding: 60px 0;
  margin-bottom: 100px;
  strong {
    font-weight: 500;
  }
  & > div {
    padding-bottom: 50px;
  }
`

const HeroBooking = styled(BookingInput)`
  @media (min-width: 980px) {
    width: 117%;
    z-index: 500;
  }
`

const HomePage = styled(PageBody)`
  @media (max-width: 768px) {
    padding-top: 79px;
  }
`

const LeadTitle = styled(H2)`
  margin-top: 64px;
  margin-bottom: 18px;
  font-size: 32px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`

function Home() {

  const [modalState, setModalState] = React.useState(false)
  const [modalLoader, setModalLoader] = React.useState(false)
  const toggleModal = () => {
    if (modalLoader === true) {
      setModalLoader(false)
      setModalState(false)
    } else {
      setModalLoader(true)
      setTimeout(() => {
        setModalState(true)
      }, 50)
    }
  }
  const loader = (modalLoader && !modalState) && <FontAwesomeIcon icon={faSpinner} spin/>
  const onCallToAction = () => {
    if (typeof window !== 'undefined' && typeof gtag !== 'undefined') {
      gtag('event','click',{
        event_category: 'Booking',
        event_action: 'open',
      });
    }
    toggleModal()
  }

  const { setPredictedSuburb } = useBooking()

  const onBookingInput = (v) => {
    setPredictedSuburb(v)
    onCallToAction()
  }

  const contentRef = React.useRef(null)
  const [scrolledDown, setScrolledDown] = React.useState(false)

  const [showBottomBar, setShowBottomBar] = React.useState(false)
  const [bottomBarContent, setBottomBarContent] = React.useState(null)

  if (typeof window !== 'undefined') {
    const scrollY = useScrollPosition(1)
    React.useEffect(() => {
      if (contentRef.current === null) return
      const { y } = contentRef.current.getBoundingClientRect()
      setScrolledDown(y < 50)
      setShowBottomBar(y < 350)
    }, [ scrollY, contentRef ])
  }


  const metaDescription = 'Searching physio near me? We’ve got you covered. ' +
    'Book an appointment with one of our experienced physiotherapists today, and feel great soon!'

  return (
    <HomePage>
      <Head>
        <title>Local Physio Network | Book a Physiotherapist near you</title>
        <meta name={'description'} content={metaDescription}/>
        <link rel={'canonical'} href={process.env.REACT_APP_APP_URL}/>
      </Head>
      <NavBar title={`Local Physio Network`} fixed={'true'}>
        <NavBarButton
          outline={!scrolledDown}
          size={'lg'}
          color={'primary'}
          className={'d-none d-lg-block'}
          role="button"
          disabled={modalState}
          onClick={onCallToAction}>
          Book an appointment {loader}
        </NavBarButton>
      </NavBar>
      <HomeJumbotron bgColor={'#fff'}>
        <HeroBooking
          focus={true}
          busy={modalLoader}
          onSubmit={onBookingInput}
        />
      </HomeJumbotron>

      <div ref={contentRef}>
      <LogoBar noMobile>
        <Container>
          <Row>
            {news.map((n, i) => (
              <LogoCol key={`news-sponsor-${i}`} hiddenXs={!n.xs}>
                <LogoImg maxWidth={'80%'} src={n.image} alt={n.title}/>
              </LogoCol>
            ))}
          </Row>

        </Container>
      </LogoBar>

      <Container>
        <Section className={'text-left text-md-center'}>
          <div className={'mb-md-5 pb-md-5'}>
            <LeadTitle>Australia’s #1 physiotherapy booking service</LeadTitle>
            <P lead>Book an appointment with a local physio in 60 seconds</P>
          </div>

          <Row className={'justify-content-center'} className={'d-none d-md-flex'}>
            <Col xs={12} md={12}>
              <Img style={{ maxWidth: '900px', borderRadius: '18px' }} src={'https://assets.localphysio.net.au/images/booking.jpg'} fluid/>
            </Col>
          </Row>
          <Row className={'d-none d-md-flex justify-content-center'} style={{ marginTop: '-50px', marginBottom: '100px' }}>
            <Col xs={12} md={8}>
              <BookingInput
                busy={modalLoader}
                onSubmit={onBookingInput}
              />
            </Col>
          </Row>
          <Steps tag={'div'}>
            <Col xs={12} md={4}>
              <P><strong>1. Search by location</strong></P>
              <Img width={200} src={'https://assets.localphysio.net.au/images/undraw_search.svg'} fluid/>
            </Col>
            <Col xs={12} md={4}>
              <P><strong>2. Book a time that suits</strong></P>
              <Img width={200} src={'https://assets.localphysio.net.au/images/undraw_date_picker.svg'} fluid/>
            </Col>
            <Col xs={12} md={4}>
              <P><strong>3. Get treated</strong></P>
              <Img width={200} src={'https://assets.localphysio.net.au/images/undraw_workout.svg'} fluid/>
            </Col>
          </Steps>
        </Section>
      </Container>

      <LogoBar>
        <Container>
          <ArticleHeading className={'pb-5'}>Some of the health insurers our clinics work with</ArticleHeading>

          <Row>
            {sponsors.map((s, i) => <LogoBlock key={`sponsor-${i}`} hiddenXs={!s.xs}>
              <LogoImg src={s.image} alt={s.title} title={s.title}/>
            </LogoBlock>)}
          </Row>
        </Container>
      </LogoBar>

      <Container>
        <HomeFaq/>
        <PhysioFaq/>
      </Container>
      </div>
      <HomeFooter footerTabs={[
				{ name: "Popular suburbs", items: suburbs },
				{ name: "Popular Cities", items: cities },
				{ name: "Popular Services", items: services },
				{ name: "Popular Pages", items: faq },
				{ name: "Popular Injuries", items: injuries },
			]} />
      <Footer noMargin/>
      <BookingModal
        isOpen={modalState}
        setBottomBarContent={setBottomBarContent}
        onClose={() => toggleModal()}
        // onComplete={toggleModal}
      />
      <BottomBar
        content={bottomBarContent}
        action={onCallToAction}
        show={modalState && showBottomBar}
      />
    </HomePage>
  )
}

Home.getInitialProps = async ({ res }) => {
//   const [
//     suburbs,
//     cities,
//   ] = await Promise.all([
//     axios.get(`${process.env.REACT_APP_API_URL}/suburbs`, {
//       params: {
//         _fields: 'id,name',
//       }
//     }).then(r => r.data.items),
//     axios.get(`${process.env.REACT_APP_API_URL}/cities`, {
//       params: {
//         priority: 'true',
//         _fields: 'id,name',
//       }
//     }).then(r => r.data.items),
//   ])

//   if (res) {
//     res.setHeader('Cache-Control', 'public, max-age=3600, s-max-age=86400, stale-while-revalidate=604800')
//   }

//   return { suburbs, cities };
  return {}
}

export default Home
