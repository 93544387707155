import React from 'react'
import { ArticleHeading, Section } from '../content'
import chunk from 'lodash/chunk'
import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import A from '@bootstrap-styled/v4/lib/A'
import styled from 'styled-components'
import { v4 } from 'uuid'
import Link from 'next/link'
import TicksUl from './TicksUl'

const ListUl = styled.ul`
  list-style: none;
  padding-left: 0;
  @media (max-width: 920px) {
    margin-bottom: 0;
  }
`

function BlockList ({
  title,
  value,
  ticks = true,
  cols = 3,
  linkPrefix = 'Physio in',
  linkTag = A,
  ...props
}) {
  const uuid = v4()
  if (!value) return null
  const _items = Array.isArray(value) ? value : value.replace('\r\n', '\n').split('\n')
	
  const items = _items.reduce((items, item, i) => {
		const j = i % cols;
		if (!items[j]) {
			items[j] = [];
		}
		items[j].push(item);
		return items;
	}, [])

  const Component = ticks ? TicksUl : ListUl

  const LinkInternal = linkTag

	const makeLink = (s) => {
		if (typeof s === 'string') {
			return s;
		}

		if (s.link) {
			return (
				<Link href={s.link} passHref>
					<LinkInternal>{linkPrefix} {s.name}</LinkInternal>
				</Link>
			)
		}

		return (
			<Link href={'/physiotherapy/[suburbId]'} as={`/physiotherapy/${s.id}`} passHref>
				<LinkInternal>{linkPrefix} {s.name}</LinkInternal>
			</Link>
		)
	}

  // const makeLink = (s) => (typeof s === 'string') ? s : (
  //   <Link href={'/physiotherapy/[suburbId]'} as={`/physiotherapy/${s.id}`} passHref>
  //     <LinkInternal>{linkPrefix} {s.name}</LinkInternal>
  //   </Link>
  // )

  return (
    <Section {...props}>
      { title && <ArticleHeading>{title}</ArticleHeading> }
      <Row>
        {items.map((col, i) => (
          <Col key={`list-${uuid}-col-${i}`}>
            <Component>
              {col.map((s, j) => (
                <li key={`list-${uuid}-${i}-${j}`}>{makeLink(s)}</li>
              ))}
            </Component>
          </Col>
        ))}
      </Row>
    </Section>
  )
}

export default BlockList
