import React from 'react'
import A from '@bootstrap-styled/v4/lib/A'
import Nav from '@bootstrap-styled/v4/lib/Nav'
import NavItem from '@bootstrap-styled/v4/lib/Nav/NavItem'
import NavLink from '@bootstrap-styled/v4/lib/Nav/NavLink'
import BlockList from '../content/BlockList'
import Container from '@bootstrap-styled/v4/lib/Container'
import styled from 'styled-components'

const Footer = styled('div')`
  margin-top: 64px;
  padding-top: 50px;
  padding-bottom: 50px;
  // background-color: #f9f9fa;
`

const UnderlineNav = styled(Nav)`
  border-bottom: 2px solid #ccc;
  .nav-item {
    min-width: 20%;
    @media (max-width: 768px) {
      text-align: center;
      width: 50%;
    }
    // text-align: center;
    text-align: left;
    &.first {
      text-align: left;
    }
  }
  .nav-link {
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    display: inline-block;
    color: #999;
    margin-bottom: -2px;
    
    padding: .5em 4px;
    &.active {
      color: #333;
      border-bottom: 2px solid #333;
    }
  }
`

const Link = styled('a')`
  color: #999;
  &:hover {
    color: #333;
    text-decoration: none;
  }
`

function HomeFooter ({
	footerTabs = 0,
  cities,
  suburbs,
}) {
  const [tab, setTab] = React.useState(0)

	const NavItems = footerTabs.map((t, index) => (
		<NavItem className={index == 0 ? 'first' : null}>
			<NavLink
				active={tab === index}
				onClick={() => setTab(index)	}
				href="javascript:;">{t.name}</NavLink>
		</NavItem>
	))

	const BlockLists = footerTabs.map((t, index) => (
		<BlockList
			linkTag={Link}
			value={t.items}
			cols={5}
			style={{ display: tab === index ? 'block' : 'none', marginTop: '30px' }}
			ticks={false}
			linkPrefix=""
		/>
	))

  return <Footer>
    <Container>
      <UnderlineNav>
				{NavItems}
      </UnderlineNav>
      {BlockLists}
    </Container>
  </Footer>
}

export default HomeFooter
