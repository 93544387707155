import { ArticleHeading } from '../content'
import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import FAQ from '../FAQ'
import React from 'react'
import P from '@bootstrap-styled/v4/lib/P'
import Ul from '@bootstrap-styled/v4/lib/Ul'
import Li from '@bootstrap-styled/v4/lib/Li'

const PhysioFaq = function () {
  return <section>
    <ArticleHeading className={'text-center pb-5'}>Physiotherapy FAQ's</ArticleHeading>
    <Row className={'justify-content-center'}>
      <Col xs={12} md={9}>
        <FAQ items={[
          {
            title: 'What is physiotherapy?',
            content: <React.Fragment>
              <P>
                Physiotherapy assists individuals to recover from a variety of injuries and problems.
                You may be suffering from stiffness, pain or just a general lack of mobility.
                Physiotherapists can use their skills and expertise to assist you in improving your quality of life.
                Physiotherapists have a wide range of tools that they can use to help you achieve these goals.
                The most effective treatment options are:
              </P>
              <Ul>
                <Li>exercise programs to regain strength and mobility</Li>
                <Li>manual therapy to reduce pain and stiffness</Li>
                <Li>muscle re-education</Li>
                <Li>soft tissue mobilisation (massage)</Li>
                <Li>acupuncture and dry needling</Li>
                <Li>Hydrotherapy</Li>
              </Ul>
              <P>To see a physiotherapist in Australia you do not require a doctor’s referral. However, physio’s will work closely with your treating doctor and other health practitioners to manage and treat complex conditions.</P>
            </React.Fragment>
          },
          {
            title: 'How long will it take for me to get better?',
            content: <React.Fragment>
              <P>This of course is hard to answer directly. The complexity of your condition, the length of time you have been suffering and other health factors all play a role in the time for recovery. The general rule of thumb would be the quicker you address the problem, the quicker it can be resolved. Physiotherapists will assess all of the relevant factors that may be increasing your pain and create a plan to resolve them as soon as possible. It is also important that you are involved in your treatment. If you are willing to make changes and do the appropriate exercises, the quicker you will get better.</P>
            </React.Fragment>
          },
          {
            title: 'Why choose physiotherapy?',
            content: <React.Fragment>
              <P>Physiotherapy is a respected profession that is trusted by the medical community. There is a strong focus on providing safe, and effective evidence based treatment to ensure that patients get the best results. Physiotherapy follows a simple 5 step approach.</P>
              <P>Assessment - your physiotherapist will thoroughly assess your condition by gaining all the information they can about your relevant history. Once the physiotherapist has sufficient subjective information they will move on to an objective assessment. This stage requires the physiotherapist to perform a number of movement assessments and tests to gauge where the deficits and weaknesses lie.</P>
              <P>Diagnosis - once the subjective and objective assessments are completed, the physiotherapist will be able to use that information to give you a diagnosis. This diagnosis will be useful in helping you understand what your problem is and what steps are required to fix it.</P>
              <P>Pain relief - most people come to physiotherapists because of pain, so a big part of the treatment process is pain relief.  This is achieved using manual techniques such as manual therapy, mobilisation, massage and exercise.</P>
              <P>Rehabilitation - this is a key component to the treatment process as there are usually weaknesses and deficits that need correcting. YOur physiotherapist will give you exercises to complete which will help correct those problems and hopefully lead to a full recovery</P>
              <P>Prevention - now that you are feeling better it is important that things remain that way. This will require patients to maintain an exercise program that will focus on continued strength, mobility and function.</P>
            </React.Fragment>
          },
          {
            title: 'Do physiotherapists bulk bill?',
            content: <React.Fragment>
              <P>Physiotherapy services across Australia can be accessed in the public health sector or private health sector. When seeing a private physiotherapist, the practice will usually have set fees that are above and beyond what your private health insurance will cover. This means that there will be a gap payment that you will incur. Most practices will have access to HICAPS or healthpoint which will enable you to make the claim on the spot. In certain circumstances physiotherapists will bulk bill, this could include; DVA, Medicare or patients in financial hardship. To access physiotherapy through DVA or Medicare you will need a referral from your GP. Speak to your local physio to find out what their prices are.</P>
            </React.Fragment>
          },
          {
            title: 'Can physiotherapists treat insurance injuries?',
            content: <React.Fragment>
              <P>Insurance claims, either workers compensation or CTP can be treated by physiotherapists. Once a claim has been made you will need to see your GP for guidance on how best to help you in recovering from your injury. You should seek a referral from your GP to a physiotherapist. Once your claim is approved a physiotherapist will be able to assess you and begin treatment immediately. You will need to liaise with your case manager throughout this process to ensure you are able to access all the services that you are entitled to. You can then book an appointment with your local physio to assist you in recovery.</P>
            </React.Fragment>
          },
          {
            title: 'Physiotherapy vs Chiropractor?',
            content: <React.Fragment>
              <P>If you are suffering from aches and pains, or recovering from an injury, there are several types of practitioners that may be able to help you. There are many overlaps between physiotherapists and chiropractors, as both will aim to treat many of the same conditions. Physiotherapists tend to have a slightly more exercise focused approach, combining that with manual therapy and hands on treatment. You will be given exercises to take home to do as part of your treatment to enhance the benefits of the treatment. This will help you build strength, improve mobility and reduce your pain. Chiropractors will focus more on spinal alignment and adjustments. Chiropractic treatment tends to be more passive and reliant on the chiropractor performing manual adjustments regularly. The evidence suggests that combining manual treatments with exercise is the most effective form of treatment for musculoskeletal conditions.</P>
            </React.Fragment>
          },
          {
            title: 'Physiotherapy vs massage',
            content: <React.Fragment>
              <P>There are many benefits to both physiotherapy and massage treatment. Massage therapy is usually thought of as an immediate relaxation technique whereas physiotherapy is more focused on rehabilitation and recovery. These services can be complimentary, and you will find that a lot of physiotherapists use massage techniques within their treatment or may even refer you to a massage therapist for additional treatment. Physiotherapists are registered under AHPRA and therefore come under strict guidelines for registration. Physiotherapists must comply with the regulations of AHPRA, which includes completing regular professional development and training to maintain their license. Massage therapists don’t come under the same level of restrictions so you must always exercise caution when seeking a massage therapist. Look for a massage therapist who is accredited and holds the appropriate qualifications. Speak to your local physio about massage therapy and whether it will be beneficial for you.</P>
            </React.Fragment>
          },
          {
            title: 'What treatment do physios use?',
            content: <React.Fragment>
              <P>Physiotherapists have an extensive tool kit of treatment options at their disposal. An experienced physio will be able to use their skills to determine what will be the most effective course of action for their clients. Physios are skilled in: manual therapy, mobilisations, massage, manipulations, exercise prescription, electrotherapy, health and fitness education, and many more. It is up to the physiotherapist to use a combination of these techniques to get the best outcomes for their patients. Your local physio can guide you to the best treatment options for your injury.</P>
            </React.Fragment>
          },
          {
            title: 'How much does physiotherapy cost?',
            content: <React.Fragment>
              <P>Physiotherapy costs vary all across the country. Sydney and Melbourne are the most expensive cities in Australia to see a physiotherapist with the average session approximately $112. Fees usually range between $90-$180 for an initial appointment and $82-$150 for a subsequent visit. This will vary greatly based on the experience of the practitioner, his or her qualifications (Phd, Masters training) and the location of the practice. Private physiotherapy is covered under “extras” for private health insurance which means that you will only pay a portion of the fees. The health fund will cover a percentage, and you will have to pay a gap. This will vary on the level of cover that you have with your insurance company. Other factors that need to be considered are the number of sessions that you may require. Although you may pay more for each session, an experienced physiotherapist may be able to have you recovered quicker and therefore your overall costs may be reduced. Have a conversation with your local physio about the cost of their service.</P>
            </React.Fragment>,
          },
        ]}/>
      </Col>
    </Row>
  </section>
}

export default PhysioFaq
