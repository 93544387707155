import React from 'react'
import Col from '@bootstrap-styled/v4/lib/Col'
import Container from '@bootstrap-styled/v4/lib/Container'
import Img from '@bootstrap-styled/v4/lib/Img'
import BsJumbotron from '@bootstrap-styled/v4/lib/Jumbotron'
import P from '@bootstrap-styled/v4/lib/P'
import Row from '@bootstrap-styled/v4/lib/Row'
import { H1 } from '../Typography'
import styled from 'styled-components'

const Title = styled(H1)`
  margin-bottom: 14px;
`
const CallToActionBlock = styled.div`
  padding: 28px 0;
  @media (max-width: 920px) {
    margin-bottom: 36px;
  }
`

const HeroImage = styled(Img)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 70px;
`

const HeroImageMobile = styled(Img)`
  border-radius: 4px;
`

const StyledHero = styled(BsJumbotron)`
  &.jumbotron {
    border-radius: 0;
    padding: 22px 15px 58px;
    background-color: ${props => props.bgColor || '#F8F9F9'};
    .container-fluid {
      @media (max-width: 920px) {
        padding: 0;
      }
    }
    @media (max-width: 920px) {
      background-color: #fff;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`

function Jumbotron ({
  children,
  title,
  lead,
  image,
  imageMobile,
  imageAlt = '',
  ...props
}) {
  return (
    <StyledHero {...props}>
      <Container fluid>
        <Row>
          <Col lg={8} xs={12} className={'d-flex align-items-center'}>
            <div className={'pr-lg-5 py-5'}>
              { title && <Title  dangerouslySetInnerHTML={{ __html: title }}/> }
              { lead && <P lead dangerouslySetInnerHTML={{ __html: lead }}/> }
              { (children) && <CallToActionBlock>
                {children}
              </CallToActionBlock> }
            </div>
          </Col>
          { image && <Col lg={4} xs={4} className={'d-none d-lg-block'}>
            <HeroImage fluid alt={imageAlt} src={image}/>
          </Col> }
        </Row>
        { imageMobile && <HeroImageMobile fluid alt={imageAlt} className={'d-block d-lg-none'} src={imageMobile}/> }
      </Container>
    </StyledHero>
  )
}

export default Jumbotron
