import React from 'react'
import Jumbotron from '../layout/Jumbotron'
import styled from 'styled-components'

const src = require('../../assets/images/2938171.jpg')

const StyledJB = styled(Jumbotron)`
  @media (max-width: 920px) {
    &.jumbotron {
      padding-top: 0;
    }
  }
`

function HomeJumbotron ({ ...props }) {
  return (
    <StyledJB
      title={`Book an experienced physiotherapist near you.`}
      lead={`Whether you need help with an existing injury, rehab or pain management, we’ve got you covered! Book a time with a physio near you, and feel great soon.`}
      image={src}
      imageMobile={'https://assets.localphysio.net.au/images/booking.jpg'}
      {...props}
    />
  )
}

export default HomeJumbotron
